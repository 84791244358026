<!--start color switcher-->
<div class="right-sidebar">
	<div class=""> <i class="zmdi zmdi-settings zmdi-hc-spin"></i>
	</div>
	<div class="right-sidebar-content">
		<p class="mb-0">Header Colors</p>
		<hr>
		<div class="mb-3">
			<button type="button" id="default-header" class="btn btn-outline-primary">Default Header</button>
		</div>
		<ul class="switcher">
			<li id="header1"></li>
			<li id="header2"></li>
			<li id="header3"></li>
			<li id="header4"></li>
			<li id="header5"></li>
			<li id="header6"></li>
		</ul>
		<p class="mb-0">Sidebar Colors</p>
		<hr>
		<div class="mb-3">
			<button type="button" id="default-sidebar" class="btn btn-outline-primary">Default Sidebar</button>
		</div>
		<ul class="switcher">
			<li id="theme1"></li>
			<li id="theme2"></li>
			<li id="theme3"></li>
			<li id="theme4"></li>
			<li id="theme5"></li>
			<li id="theme6"></li>
		</ul>
	</div>
</div>
<!--end color switcher-->